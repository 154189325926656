import React, { useMemo } from 'react';
import classnames from 'classnames';
import css from 'styled-jsx/css';

import { toSpacingShorthand } from './parseSpacing';

const root = css.resolve`
  .positionRelative {
    position: relative;
  }

  .positionAbsolute {
    position: absolute;
  }

  .flexRow,
  .flexColumn,
  .centerContent {
    display: flex;
  }

  .centerContent {
    align-items: center;
    justify-content: center;
  }

  .spaceBetween {
    justify-content: space-between;
  }

  .flexRow {
    flex-direction: row;
  }

  .flexColumn {
    flex-direction: column;
  }

  .flexWrap {
    flex-wrap: wrap;
  }

  .alignCenter.flexRow {
    align-items: center;
  }

  .justifyCenter.flexRow {
    justify-content: center;
  }

  .alignEnd.flexRow {
    justify-content: flex-end;
  }

  .alignCenter.flexColumn {
    justify-content: center;
  }

  .alignEnd.flexColumn {
    align-items: flex-end;
  }

  .justifyEnd.flexColumn {
    justify-content: flex-end;
  }

  .isInline {
    display: inline-block;
  }

  .isBlock {
    display: block;
  }

  .flexRow.isInline,
  .flexColumn.isInline,
  .centerContent.isInline {
    display: inline-flex;
  }

  .flexGrow {
    flex: 1;
    min-width: 0;
  }

  .noFlexShrink {
    flex-shrink: 0;
  }

  .isCentered {
    margin: 0 auto;
  }

  .fullWidth {
    width: 100%;
  }

  .fullHeight {
    height: 100%;
  }

  .coverParent {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

// TODO: see if we can reduce the size of this w/o defining
// all these props we could do a rest spread of some kind
export interface WithSpacingProps {
  spacing?: string;
  margin?: string;
  padding?: string;
  style?: React.CSSProperties;
  className?: string;
  flexBox?: boolean;
  flexRow?: boolean;
  flexColumn?: boolean;
  flexGrow?: boolean;
  noFlexShrink?: boolean;
  flexWrap?: boolean;
  centerContent?: boolean;
  alignCenter?: boolean;
  alignEnd?: boolean;
  justifyEnd?: boolean;
  justifyCenter?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  positionRelative?: boolean;
  positionAbsolute?: boolean;
  pointerEvents?: 'none' | 'all';
  coverParent?: boolean;
  isCentered?: boolean;
  isInline?: boolean;
  isBlock?: boolean;
  width?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
  height?: number | string;
  minHeight?: number | string;
  maxHeight?: number | string;
  zIndex?: number;
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
  spaceBetween?: boolean;
  gap?: string;
}

const withSpacing = <P extends WithSpacingProps>(Component: React.FC<P>) => {
  const ComponentWithSpacing: React.FC<P> = ({
    spacing = '',
    margin = '',
    padding = '',
    className = '',
    style = {},
    flexBox,
    flexRow,
    flexColumn,
    flexGrow,
    noFlexShrink,
    flexWrap,
    centerContent,
    alignCenter,
    alignEnd,
    justifyCenter,
    justifyEnd,
    fullWidth,
    fullHeight,
    children,
    positionRelative,
    positionAbsolute,
    pointerEvents,
    coverParent,
    isCentered,
    isInline,
    isBlock,
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    zIndex,
    top,
    right,
    bottom,
    left,
    spaceBetween,
    gap,
    ...props
  }) => {
    const styleInternal: React.CSSProperties = {};

    // back-compat for 'spacing' prop
    padding = padding || spacing;

    if (padding) {
      styleInternal.padding = toSpacingShorthand(padding);
    }

    if (margin) {
      styleInternal.margin = toSpacingShorthand(margin);
    }

    if (isDefined(maxWidth)) styleInternal.maxWidth = maxWidth;
    if (isDefined(minWidth)) styleInternal.minWidth = minWidth;
    if (isDefined(width)) styleInternal.width = width;
    if (isDefined(maxHeight)) styleInternal.maxHeight = maxHeight;
    if (isDefined(minHeight)) styleInternal.minHeight = minHeight;
    if (isDefined(height)) styleInternal.height = height;
    if (isDefined(zIndex)) styleInternal.zIndex = zIndex;
    if (isDefined(top)) styleInternal.top = top;
    if (isDefined(right)) styleInternal.right = right;
    if (isDefined(bottom)) styleInternal.bottom = bottom;
    if (isDefined(left)) styleInternal.left = left;
    if (isDefined(gap)) styleInternal.gap = gap;

    if (pointerEvents) styleInternal.pointerEvents = pointerEvents;

    const styleMerged = {
      ...styleInternal,
      ...style,
    };

    return (
      <Component
        {...(props as P)}
        // some wrapped components (eg. Text) need this forwarding
        isInline={isInline}
        className={classnames(className, root.className, {
          flexRow: flexRow || flexBox,
          flexColumn,
          flexGrow,
          flexWrap,
          noFlexShrink,
          isInline,
          isBlock,
          positionRelative,
          positionAbsolute,
          coverParent,
          fullHeight,
          fullWidth,
          isCentered,
          centerContent,
          alignCenter,
          alignEnd,
          justifyCenter,
          justifyEnd,
          spaceBetween,
        })}
        style={useMemo(() => styleMerged, [JSON.stringify(styleMerged)])}
      >
        {children}
        {root.styles}
      </Component>
    );
  };

  return ComponentWithSpacing;
};

const isDefined = (value) => typeof value !== 'undefined';

export default withSpacing;
