import type {
  SongwhipArtist as DefaultArtistPayload,
  SongwhipAlbum as DefaultAlbumPayload,
  SongwhipTrack as DefaultTrackPayload,
  SongwhipCustomPage as DefaultCustomPagePayload,
  ItemConfig as DefaultItemConfig,
  SongwhipUser,
  CustomPageConfig,
  Geolocation,
  ItemLinks,
} from '@theorchard/songwhip-api';

import { PageSectionDefinition } from '~/app/components/ItemPage/types';

import { MappedArtist, MappedAlbum, MappedTrack } from './mapper';

export * from '@theorchard/songwhip-api';
export * from './accounts/types';

export type HttpMethods = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type UserRole = 'admin' | 'employee';

export interface AlbumLookupConfig {
  upc?: string | null;
  isrc?: string | null;
  links?: ItemLinks;
}

interface AnalyticsUsersReport {
  maximum: number;
  rows: {
    date: string;
    value: string;
  }[];
}

interface AnalyticsOpenMusicServiceReport {
  total: number;
  rows: {
    name: string;
    value: number;
  }[];
}

export interface AnalyticsOpenExternalServiceReport {
  total: number;
  rows: {
    name: string;
    url: string;
    value: number;
  }[];
}

export interface AnalyticsReferrersReport {
  total: number;
  rows: {
    name: string;
    value: number;
  }[];
}

interface AnalyticsCountriesReport {
  total: number;
  rows: {
    name: string;
    value: number;
    countryCode: string;
  }[];
}

type BaseItemConfig = DefaultItemConfig<{
  layout?: {
    hero?: PageSectionDefinition[];
    main?: PageSectionDefinition[];
  };
}>;

export interface AlbumConfig extends DefaultItemConfig<BaseItemConfig> {
  /**
   * Indicate which linked artists should be displayed on the page.
   * By default we only show the main/first artist. Eventually it
   * would be great to always show all artists but I don't yet
   * trust that songwhip-lookup isn't returning duplicate artists.
   *
   * This config allows us to selectively pick which artist ids are shown
   * to cover the case where some linked artists are unwanted and we
   * can hide them.
   */
  showArtistIds?: number[];
}

export type ArtistConfig = BaseItemConfig;
export type TrackConfig = AlbumConfig;

// Create specific payload types based on the item config defined in this project.
// This avoids us storing really front-end specific types in songwhip-api.
export type SongwhipArtist = DefaultArtistPayload<BaseItemConfig>;
export type SongwhipAlbum = DefaultAlbumPayload<AlbumConfig> & {
  lookupConfig?: AlbumLookupConfig;
};
export type SongwhipTrack = DefaultTrackPayload<TrackConfig>;
export type SongwhipCustomPage = DefaultCustomPagePayload<CustomPageConfig>;

export type SongwhipItem = SongwhipArtist | SongwhipAlbum | SongwhipTrack;

export interface AnalyticsPayload {
  days: number;
  reports: {
    users: AnalyticsUsersReport;
    openMusicService?: AnalyticsOpenMusicServiceReport;
    openExternalService?: AnalyticsOpenExternalServiceReport;
    countries: AnalyticsCountriesReport;
    referrers: AnalyticsReferrersReport;
  };
}

export interface Analytics {
  totalDays: number;
  visitors: AnalyticsUsersReport;
  openMusicService?:
    | AnalyticsOpenMusicServiceReport
    | AnalyticsOpenExternalServiceReport;
  countries: AnalyticsCountriesReport;
  referrers: AnalyticsReferrersReport;
}

export type GetAllApiParams = {
  afterId?: number;
  beforeId?: number;
  direction?: 'asc' | 'desc';
  limit?: number;
};

export interface GetAllApiPayload<
  TItemPayload extends SongwhipArtist | SongwhipAlbum | SongwhipTrack,
> {
  data: TItemPayload[];

  links: {
    next?: string;
    prev?: string;
  };
}

export interface GetAllData {
  items: (MappedArtist | MappedAlbum | MappedTrack)[];
  nextPageApiUrl?: string;
  prevPageApiUrl?: string;
}

export enum SubscriptionTypes {
  PRO_ARTIST = 'pro-artist',
  PRO_BUSINESS = 'pro-business',
  ORCHARD = 'orchard',
}

export type FeatureFlags = SongwhipUser['featureFlags'];

// TODO: use @theorchard/songwhip-api types
export interface CheckItemsInCatalogResult {
  artists: {
    spotifyId: string;
    isPartOfCatalog: boolean;
  }[];

  albums: {
    spotifyId: string;
    isPartOfCatalog: boolean;
  }[];

  tracks: {
    isrc: string;
    isPartOfCatalog: boolean;
  }[];
}

export interface ArtistShow {
  name: string;
  venueName: string;
  url: string;
  localDate: string;
  geolocation?: Geolocation;
}
